import axiosInstance from './axiosConfig';

const sendDailyVisit = (): void => {
    const today: string = new Date().toISOString().slice(0, 10); // Формат YYYY-MM-DD
    const lastVisit: string | null = localStorage.getItem('lastVisit');

    if (lastVisit !== today) {
        axiosInstance.post('visit/')
            .then(() => {
                console.log('Visit registered');
                localStorage.setItem('lastVisit', today);
            })
            .catch(error => console.error('Error registering visit:', error));
    }
};

export default sendDailyVisit;