// api.ts
import axiosInstance from './axiosConfig';

export interface StakeInfo {
    stakePubkey: string;
    stakeType: string;
    accountBalance: number;
    creditsObserved: number;
    delegatedStake: number;
    delegatedVoteAccountAddress: string;
    activationEpoch: number;
    staker: string;
    withdrawer: string;
    unixTimestamp?: number;  // необязательное поле
    epoch?: number;  // необязательное поле
    custodian?: string;  // необязательное поле
    rentExemptReserve: number;
    activeStake?: number;  // необязательное поле
    deactivationEpoch?: number;  // необязательное поле
}

// Получение списка элементов
export const getStake = async (): Promise<StakeInfo[]> => {
    const response = await axiosInstance.get<StakeInfo[]>('get_stake/', {  timeout: 10000, });
    return response.data;
};
