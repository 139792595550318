// ItemsComponent.tsx
import React, { useState, useEffect } from 'react';
import { getStake, StakeInfo} from '../axios/wallet';

const ItemsComponent: React.FC = () => {
  const [stakeAccounts, setItems] = useState<StakeInfo[]>([]);

  useEffect(() => {
    const getItems = async () => {
      const fetchedItems = await getStake();
      setItems(fetchedItems);
    };

    getItems().catch(console.error);
  }, []);

  return (
    <div>
      <h2>Items</h2>
      <ul>
        {stakeAccounts.map(item => (
          <li key={item.accountBalance}>{item.accountBalance}: {item.stakePubkey}</li>
        ))}
      </ul>
    </div>
  );
};

export default ItemsComponent;
