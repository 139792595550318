// Header.js
import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Header.module.css';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const Header = () => {
    const { connected } = useWallet();

    return (
        <header className={styles.headerContainer}>
            <div className={styles.logo}>
                <img src="/logo_solana.svg" alt="Логотип" className="logo"/>
            </div>
            <nav className={styles.nav}>
                <ul>

                    <li>
                        <Link to="/">Main</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                    <li>

                        <WalletMultiButton className={styles.wallet_button}/>
                        {/*<button className={styles.wallet_button}>wallet</button>*/}
                    </li>
                </ul>

            </nav>

        </header>
    );
};

export default Header;
