import axios from 'axios';

// Создание экземпляра axios с предварительной конфигурацией
const axiosInstance = axios.create({
    baseURL: 'https://starpaw.wtf/api', // Базовый URL
    timeout: 1000, // Таймаут запроса
    headers: {'X-Custom-Header': 'foobar'} // Пользовательские заголовки
});

axiosInstance.interceptors.request.use(config => {
    // Добавление токена аутентификации к заголовкам (если есть)
    // const token = localStorage.getItem('authToken');
    // if (token) {
    //     config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
}, error => {
    // Обработка ошибки запроса
    return Promise.reject(error);
});

export default axiosInstance;
