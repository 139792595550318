import React from 'react';
import styles from './FAQ.module.css'; // Импортируем наши стили

const FAQ = () => {
    return (
        <section className={styles.faq}>
           <div className={styles.maintenance}>
                <div className={styles.mContent}>
                    {/*<h1 className={styles.maintenanceT}>We are currently undergoing maintenance</h1>*/}
                    <h1>Section Under Development</h1>
                    <p>Expected availability: <strong>March 1, 2024</strong></p>
                </div>
            </div>
        </section>
    );
};

export default FAQ;