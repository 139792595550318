import React from 'react';
import FeatureCard from './FeatureCard';
import styles from './FeaturesSection.module.css';

// Предполагается, что у вас есть массив объектов features или вы можете их добавить напрямую
const features = [
    {
        id: 1,
        title: 'VALIDATOR INFO',
        description: 'Your transactions .',
        icon: 'icons/card/top/paw_5.webp', // Путь к иконке
        bot_icon: 'icons/card/bot/star_2_2.webp', // Путь к иконке
    }, {
        id: 2,
        title: 'Secure Transactions',
        description: 'Your transactions are always secure with us.',
        icon: 'icons/card/top/cude.webp', // Путь к иконке
        bot_icon: 'icons/card/bot/one_star.webp',
    }, {
        id: 3,
        title: 'HOW TO STAKE',
        description: 'Your transactions are always secure with us.',
        icon: 'icons/card/top/mid.webp', // Путь к иконке
        bot_icon: 'icons/card/bot/D.webp',
    }, {
        id: 4,
        title: 'REWARDS',
        description: 'Your transactions are always secure with us.',
        icon: 'icons/card/top/cude.webp', // Путь к иконке
        bot_icon: 'icons/card/bot/one_star_2.webp',
    }, {
        id: 5,
        title: 'ROAD MAP',
        description: 'Your transactions are always secure with us.',
        icon: 'icons/card/top/paw_5.webp', // Путь к иконке
        bot_icon: 'icons/card/bot/star_4_2.webp',
    },
];

const FeaturesSection = () => {
    return (
        <section className={styles.featuresSection}>
            {/*<button className={styles.bannerButton}>Start Staking</button>*/}
            <div className={styles.container}>
                {features.map(feature => (
                    <FeatureCard
                        key={feature.id}
                        title={feature.title}
                        description={feature.description}
                        icon={feature.icon}
                        bot_icon={feature.bot_icon}
                    />))}
            </div>
        </section>
    );
};

export default FeaturesSection;
