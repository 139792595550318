import React, {useState} from 'react';
import {motion} from 'framer-motion';
import styles from './FeatureCard.module.css';
import StakeAccounts from "../StakeTable/StakeTable";

const FeatureCard = ({title, description, icon, bot_icon}) => {
    const [isFlipped, setIsFlipped] = useState(false);


    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    const cardVariants = {
        front: {
            rotateY: 0,
            scale: 1,
            zIndex: 1
        },
        front_: {
            rotateY: 0,
            scale: 1,
            zIndex: 2,
            position: 'absolute',
            height: '600px',
            left: '50%',
            marginTop: '111px', // Центрирование карточки
            // left: '5%',
            transform: 'translate(-50%, -50%)',
            overflowX: 'auto',
            overflowY: 'auto',
            visibility: "visible",

        },
        back: {

            rotateY: 180,
            scale: 1.8,
            zIndex: 1,
            position: 'absolute',
            height: '1px',
            // x: '-50%',
            // y: '-50%',
            transform: 'translate(-50%, -50%)'
            , visibility: 'hidden'
        }
    };

    let div = <>
        <div className={styles.cards} onClick={handleClick}>
            <motion.div
                className={styles.card}
                variants={cardVariants}
                animate={isFlipped ? "back" : "front"}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}

                // style={{position: 'absolute'}}
            >

                <img src={icon} alt={`${title} icon`} className={styles.icon}/>
                <div className={styles.content}>
                    <h3 className={styles.title}>{title}</h3>
                    <p className={styles.description}>{description}</p>
                </div>

                <img src={bot_icon} alt={`${title} icon`} className={styles.botIcon}/>


            </motion.div>
            <motion.div
                className={styles.backFace}
                variants={cardVariants}
                animate={isFlipped ? "front_" : "back"}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
                // style={{position: 'absolute', rotateY: -180}}
            >
                {/* Содержимое обратной стороны карточки */}
                {isFlipped && (

                    <div>
                        <div className={styles.maintenance}>
                            <div className={styles.mContent}>
                                {/*<h1 className={styles.maintenanceT}>We are currently undergoing maintenance</h1>*/}
                                <h1>Section Under Development</h1>
                                <p>Expected availability: <strong>March 1, 2024</strong></p>
                            </div>

                        </div>

                    </div>
                    // <StakeAccounts/>
                        )}
            </motion.div>
        </div>
    </>;
    return div;
};

export default FeatureCard;
