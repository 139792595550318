import React, {useEffect, useState} from 'react';
import styles from './MainBanner.module.css';
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {LAMPORTS_PER_SOL} from "@solana/web3.js";
import * as web3 from "@solana/web3.js";
// import * as walletKeyPair from "@solana/web3.js/src/layout";

const MainBanner = () => {
    const [isMobile, setIsMobile] = useState(false);
    // const [scaleRatio, setScaleRatio] = useState(0.2);
    const {connection} = useConnection();
    const {publicKey, sendTransaction, signTransaction} = useWallet();

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 450);
        };

        // Проверка при монтировании компонента
        checkMobile();

        const scaleDiv = () => {
            const image = document.querySelector('.header-container img') as HTMLImageElement;
            if (image) {
                const scaleRatio = image.offsetWidth / image.naturalWidth;
                const overlay = document.querySelector('.banScale') as HTMLElement;

                if (overlay) {
                    overlay.style.transform = `scale(${scaleRatio - 0.2})`;
                    overlay.style.right = `${215 - (image.naturalWidth - image.offsetWidth) / 3.3}px`;
                    overlay.style.top = `${112 - (image.naturalHeight - image.offsetHeight) / 2.2}px`;
                }
            }
        };

        scaleDiv();

        // Обработчик изменения размера окна
        window.addEventListener('resize', checkMobile);
        window.addEventListener('resize', scaleDiv);
        // Очистка при размонтировании

        return () => window.removeEventListener('resize', checkMobile);
    }, []);
    if (isMobile) {
        return <></>
    }

    const handleClick = async () => {
        // const solanaWeb3 = require('@solana/web3.js');


        if (publicKey) {
            // Create a stake account
            const stakeAccount = web3.Keypair.generate();
            let createStakeAccountInstruction = web3.StakeProgram.createAccount({
                fromPubkey: publicKey,
                stakePubkey: stakeAccount.publicKey,
                authorized: new web3.Authorized(publicKey, publicKey),
                lamports: web3.LAMPORTS_PER_SOL * 0.02,
            });

            console.log(stakeAccount.publicKey.toBase58())
            // Initialize the transaction for the stake account creation
            let createStakeAccountTransaction = new web3.Transaction().add(createStakeAccountInstruction);
            createStakeAccountTransaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
            createStakeAccountTransaction.feePayer = publicKey;
            createStakeAccountTransaction.partialSign(stakeAccount);
            // createStakeAccountTransaction = await web3.sendAndConfirmTransaction(
            //     connection,
            //     createStakeAccountTransaction,
            //     [walletKeyPair, stakeAccount],
            // );

            sendTransaction(createStakeAccountTransaction, connection).then((sig) => {

                console.log(sig);

            });
            // Build and send the delegation transaction
            const votePubkey = new web3.PublicKey('9V3mbi8z9MHicFQN9n9CGEMfuX4yKaBjJNuzyTUxaWXi')
            let delegateInstruction = web3.StakeProgram.delegate({
                stakePubkey: stakeAccount.publicKey,
                authorizedPubkey: publicKey,
                votePubkey,
            });
            // Initialize the transaction for delegation
            let delegateTransaction = new web3.Transaction().add(delegateInstruction);
            delegateTransaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
            delegateTransaction.feePayer = publicKey;
            // delegateTransaction.sign(signTransaction);
            if (signTransaction) {
                let sign_createStakeAccountTransaction = await signTransaction(delegateTransaction)
                sendTransaction(sign_createStakeAccountTransaction, connection).then((sig) => {

                    console.log(sig);

                });
            }


            // delegateTransaction = await solanaWeb3.sendAndConfirmTransaction(
            //     connection,
            //     delegateTransaction,
            //     [walletKeyPair],
            // );

            connection.getAccountInfo(publicKey).then((info) => {
                console.log(info);
            });
        }
    }

    return (
        <section className={`${styles.mainBanner} banScale`}>
            <div className={styles.bannerContent}>
                <div className={styles.bannerTitle}>StarPaw</div>
                <p className={styles.bannerSubtitle}>
                    Secure and efficientstaking</p>
                {/* Предположим, что у вас есть изображение, которое вы хотите использовать в баннере */}
                {/*<img src="/bann.webp" alt="Cosmic Cat" className={styles.bannerImage}/>*/}
                <button className={styles.bannerButton} onClick={handleClick}>Start Staking</button>
            </div>
        </section>
    );
};

export default MainBanner;
