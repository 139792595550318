import React from 'react';
import styles from './Contact.module.css'; // Импортируем наши стили

const Contact = () => {
    return (
        <section className={styles.contact}>
            <div className={styles.container}>
                <div className={styles.contactInfo}>
                    <h2>Contact Us</h2>
                    <p>If you have any questions, proposals, or need assistance, feel free to reach out through the
                        following channels:</p>
                    <p>Email: <a href="mailto:info@starpaw.wtf" className={styles.contactLink}>info@starpaw.wtf</a></p>
                    <p>Telegram: <a href="https://t.me/starpaw_wft" className={styles.contactLink}>@starpaw.wft</a></p>
                    <p>We're always here to help and look forward to connecting with you!</p>
                </div>
            </div>

        </section>
    );
};

export default Contact;