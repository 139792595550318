import React from 'react';
import styles from './About.module.css'; // Импортируем наши стили

const About = () => {
    return (
        <section className={styles.about}>
            <div className={styles.container}>
                <h2 className={styles.title}>About Star Paw</h2>
                <h3 className={styles.title_}>Mission:</h3>
                <p className={styles.description}>
                    Our mission is to provide expert validation services for the Solana network,
                    ensuring the utmost speed and security for its transaction processes.
                    We aim to be the bridge that connects the reliability of traditional IT systems with the
                    pioneering solutions of blockchain technology.
                    We are dedicated to fostering a high-performance environment that upholds the integrity
                    and continuous advancement of the Solana ecosystem.
                </p>
              <h3 className={styles.title_}>History:</h3>
                <p className={styles.description}>

Our journey began with an immersion into the world of IT,
                    accumulating substantial experience in development and systems administration.
                    In search of a platform that melds innovative technology with high performance,
                    we discovered Solana — a blockchain that promises to revolutionize the industry with its speed and scalability.
                    The ideals of Solana resonated with our belief in the necessity of a progressive and accessible financial network.
                </p>
            </div>
        </section>
    );
};

export default About;