import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';

import {
    Routes,
    Route,
    // Link,
} from "react-router-dom";

import Navbar from './components/Navbar/Header';
import MainBanner from './components/MainBanner/MainBanner';
import FeaturesSection from './components/FeaturesSection/FeaturesSection';
import About from './components/About/About';
// import HowToStake from './components/HowToStake/HowToStake';
import Footer from './components/Footer/Footer';
import backgroundImage from './assets/starpaw_2.webp';

import styles from "./components/MainBanner/MainBanner.module.css";
import Contact from "./components/Contact/Contact";
import FAQ from "./components/FAQ/FAQ";
import WalletContextProvider from "./components/Wallet/WalletContextProvider";
import ItemsComponent from "./pages/ItemsComponent";
import sendDailyVisit from "./axios/sendDailyVisit";

function App() {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        sendDailyVisit();
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 500);
        };

        // Проверка при монтировании компонента
        checkMobile();

        // Обработчик изменения размера окна
        window.addEventListener('resize', checkMobile);
        // Очистка при размонтировании
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    
     if (isMobile) {
         return (
             <div style={{
                 position: 'fixed',
                 top: 0,
                 left: 0,
                 width: '100%',
                 height: '100%',
                 background: '#261d1d',
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',
                 textAlign: 'center',
                 padding: '20px',
                 zIndex: 1000,
             }}>
                 The website is under construction.<br/>
                 Please visit us from a computer.
             </div>
    
         );
     }

    return (
        <>
            <div className="App">

                <WalletContextProvider>
                    <div className="header-container">
                        <img src={backgroundImage} alt={`icon`} className='bannerImg'/>
                        <Navbar/> {/* Навигационная панель */}
                        <MainBanner/> {/* Главный баннер */}
                    </div>
                    <div className="header-all">
                    </div>

                    <Routes>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/test" element={<ItemsComponent/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/faq" element={<FAQ/>}/>
                        <Route path="" element={<FeaturesSection/>}/>

                    </Routes>
                    {/*<Route path="/how-to-stake" component={HowToStake}/>*/}
                    {/* Добавьте другие маршруты здесь */}
                </WalletContextProvider>
                <Footer/> {/* Футер */}

            </div>

        </>
    );
}

export default App;
